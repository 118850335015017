require('./styles/app.scss');
require('./styles/public.css');


console.log('Booting CIRS Frontend');

const $ = require('jquery');
global.moment = require('moment');
// remove after moving all into webpack
global.$ = global.jQuery = $;

require('bootstrap');

require('eonasdan-bootstrap-datetimepicker');
require('bootstrap-datepicker');


$('body').on('hidden.bs.modal', '.modal', function () {
    $(this).removeData('bs.modal');
});
